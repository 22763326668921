:root {
  --r3wind-black: #0F1013;
  --r3wind-dark-blue: #000021;
  --r3wind-grey: #323232;
  --r3wind-primary-color: #FF65FF;
  --r3wind-gradient: linear-gradient(180deg, rgba(255, 101, 255, 1) 35%, rgba(247, 64, 124, 1) 100%);
  --r3wind-gradient-hover: linear-gradient(150deg, rgba(255, 101, 255, 1) 35%, rgba(247, 64, 124, 1) 100%);
  --r3wind-share-gradient: linear-gradient(90deg, rgba(250, 106, 255, 1) 0%, rgba(184, 55, 255, 1) 28%, rgba(205, 0, 212, 1) 57%, rgba(247, 64, 124, 1) 100%);
  --r3wind-share-gradient-hover: linear-gradient(90deg, rgba(250, 106, 255, 1) 0%, rgba(184, 55, 255, 1) 20%, rgba(205, 0, 212, 1) 40%, rgba(247, 64, 124, 1) 100%);
}

body {
  background-color: var(--r3wind-black);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Prompt','Poppins', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}

button {
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 7px;
  color: white;
  background-origin: border-box;
  border: solid 1px transparent;
  cursor: pointer;
}

small {
  font-size: x-small;
  font-weight: lighter;
}

.primary-button {
  background-image: var(--r3wind-gradient);
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), var(--r3wind-gradient);
  background-clip: content-box, border-box;
  box-shadow: 1px 1000px 1px black inset;
}

.primary-button:hover {
  background-image: var(--r3wind-gradient-hover);
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), var(--r3wind-gradient-hover);

}

.secondary-button {
  background-clip: border-box;
  background-color: var(--r3wind-primary-color);
  background-image: var(--r3wind-gradient);
}

.secondary-button:hover {
  background-image: var(--r3wind-gradient-hover);
}

.share-button {
  position: absolute;
  top: 20px;
  border: none;
  right: 20px;
  z-index: 10;
  width: 90px;
  height: 35px;
  font-size: medium;
  background: var(--r3wind-share-gradient);
}

.share-button:hover {
  background: var(--r3wind-share-gradient-hover);
}

.connect-button {
  background: var(--r3wind-share-gradient);
  width: 100px;
  height: 35px;
  border: none;
  font-weight: lighter;
}

.connect-button:hover {
  background: var(--r3wind-share-gradient-hover);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.video-responsive {
  padding: 10px;
  
}


.modal {
  background-color: #282828;
  position: fixed;
  color: #fff;
  font-size: large;
  width: 100vw;
  height: 60vh;
  padding-top: 10px;
  left: 0px;
}

@media only screen and (min-width: 768px) {

  /* For desktop: */
  .modal {
    width: 60vw;
    left: 20%;
  }

  .page-not-found-div span{
    font-size: 50px;
  }
}

.modal>.header {
  width: 100%;
  font-size: 18px;
  text-align: left;
  left: 0px;
  padding: 10px;
}

.modal>.content {
  font-weight: lighter;
  width: 100%;
  padding: 10px 15px;
}

.modal>.actions {
  padding: 20px;
  margin: auto;
  text-align: center;
}

.modal>.actions td {
  border: solid 1px;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 30px;
  background: none;
}

.link-container {
  display: inline-block;
  vertical-align: middle;
}

.link-container table {
  margin-left: 10px;
}

.link-container tr,
td {
  border: 2px solid #aeaeae;
  border-spacing: 0px;
  border-collapse: separate;
  font-size: small;
  font-weight: lighter;
  ;
  padding: 8px;
}

.link-container td:first-of-type {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.link-container td:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  top: 30px;
  font-size: small;
  width: 120px;
}

.tooltip .tooltiptext {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.783);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.creator-div {
  text-align: left;
  background-color: var(--r3wind-dark-blue);
  padding: 15px 0px 15px 15px;
  display: flex;
  /*justify-content: center;*/
  color: white;
  font-weight: bold;
}

.creator-pic {
  background-color: #eeeeee;
  background-image: var(--r3wind-gradient);
  border-width: solid 3px;
  height: 40px;
  width: 40px;
  float: left;
  border-radius: 50%;
  display: flex;
  justify-content: left;
  display: flex;
  justify-content: left;
}

.creator-pic img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: "hidden",
}

.creator-info {
  background-color: transparent;
  height: 100%;
  float: left;
  margin-left: 30px;
}

.creator-selection-div {
  background-color: var(--r3wind-black);
  color: #c8c7c7;
  vertical-align: middle;
  padding: 15px 0px;
  text-align: center;
}

.creator-selection-div button {
  color: #c8c7c7;
  border-radius: 15px 15px;
  width: 100px;
  margin: 0px 10px 0px 10px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: solid 1px var(--r3wind-grey);
  cursor: pointer;
  overflow: hidden;
  outline: none;
  align-items: center;
}

.creator-selection-div button:hover {
  color: var(--r3wind-primary-color);
  border-color: #FF65FF;
}

.selection-container {
  color: white;
  text-align: center;
}

.creator-container {
  background-color: var(--r3wind-black);
  position: relative;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: var(--r3wind-black);
  position: relative;
  border: solid 1px var(--r3wind-grey);
  border-radius: 10px;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 5px;
  width: 200px;
  height: 300px;
}

.nft-container {
  left: 5%;
  width: 100%;
}

.nft-embed-wrapper {
  background-color: var(--r3wind-black);
  height: 200px;
  /* Center horizontally*/
  position: relative;
  display: block;
}

.nft-embed-wrapper>iframe {
  background-color: var(--r3wind-black);
  display: block;
  width: 100% !important;
  height: 100% !important;
  border: 0;

  top: 0;
  left: 0;
}

.nft-info {
  margin-top: 5px;
  height: 35px;
  padding: 0 5px;
}

.nft-info-left {
  float: left;
  width: 50%;
  text-align: left;
}

.nft-info-right {
  float: right;
  width: 50%;
  text-align: right;
}

.nft-editions,
.nft-price {
  font-size: x-small;
  color: #969599;
}

.nft-actions {
  margin: 5px 0px;
  height: 35px;
  padding: 0 5px;
  overflow-x: auto;
}

.nft-actions button {
  font-size: x-small;
  width: calc(50%-4px);
}

.nft-actions button:first-child {
  float: left;
  text-align: left;
}

.nft-actions button:last-child {
  float: right;
  text-align: right;
}

.page-not-found-div {
  font-size: 40px;
  color: #fff;
  position: absolute;
  top:20%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.page-not-found-div span {
  background: var(--r3wind-share-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}